<template>
    <div class="content-wrapper">
        <!-- 托管服务-托管学生-->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <expand-filter
                    :formData="formData"
                    :closeWidth="900"
                    marginTop="10px"
                    marginBottom="10px"
                    borderRadius="4px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @changeSel="changeSel"
                    @datePickerBlur="datePickerBlur"
                ></expand-filter>
                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                    >
                        <template #title="{data}" v-if="detailState" >
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="handleDetails(data)"
                                >{{ data.title }}</span
                            >
                        </template>
                        <template #title="{data}" v-else>
                            <span>{{ data.title }}</span>
                        </template>
                        <template #depositClasses="{data}">
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="handleClass(data)"
                                >班级详情</span
                            >
                        </template>
                        <template v-slot:operation="slotData">
                            <xk-button
                                v-hasPermi="['schooldeposit:edit']"
                                size="mini"
                                @click="handelEdit(slotData.data)"
                                :style="{
                                    color: '#3C7FFF',
                                    border: 'none',
                                    background: 'none',
                                }"
                                >编辑</xk-button
                            >
                            <xk-button
                                v-hasPermi="['schooldeposit:delete']"
                                size="mini"
                                style="color: #f26231; border: none; background:none;"
                                @click="handelDetele(slotData.data)"
                                >删除</xk-button
                            >
                        </template>
                    </table-data>
                    <pagination
                        v-hasPermi="['schooldeposit:list']"
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList()"
                    />
                </div>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
        <!-- 添加、编辑 -->
        <dialog-wrapper
            :dialogObj="addObj"
            @handleClose="addClose"
        >
            <el-form
                v-if="addObj.dialogVisible"
                class="form common-form"
                :model="addRuleForm"
                :rules="addRules"
                ref="addRuleForm"
                label-width="94px"
				label-position="right"
            >
                <el-form-item
                    label="项目名称"
                    prop="title"
                >
                    <el-input
                        v-model="addRuleForm.title"
                        show-word-limit
                        maxlength="30"
                        placeholder="请输入项目名称"
						class="input-width-medium"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="addObj.type == 'add'"
                    label="班级"
                    prop="class"
                >
                <!-- <m-tree-select
                    v-if="addObj.dialogVisible"
                    ref="tree"
                    filterable
                    multiple
                    v-model="addRuleForm.class"

                    :collapse-tags="true"
                    :checkStrictly="false"
                    :data="multipleChoiceClasses"
                  ></m-tree-select> -->
                    <el-select
                        v-model="addRuleForm.class"
                        placeholder="请选择班级"
						class="input-width-medium"
                    >
                        <el-option
                            :value="selectTree"
                            disabled
                            class="open-style"
                        >
                            <el-tree
                                class="stafftree"
                                :data="multipleChoiceClasses"
                                :props="defaultProps"
                                ref="tree"
                                show-checkbox
                                check-strictly
                                :expand-on-click-node="false"
                                check-on-click-node
                                @check-change="checkChangeClick"
                            ></el-tree>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="托管日期"
                    prop="date"
                >
                    <el-date-picker
                        :style="{width: '452px', height: '36px'}"
                        v-model="addRuleForm.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="选择开始日期"
                        end-placeholder="选择结束日期"
                        value-format="yyyy-MM-dd"
                        :disabled="addObj.type == 'edit' && flag"
                        @change="getNewDate"
                    />
                </el-form-item>
                <div style="text-align: right;width:546px;">
                    <el-button @click="addClose" class="dialog-reset-button" >取消</el-button>
                    <el-button @click="addSubmit()" type="primary">保存</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
        <!-- 班级详情 -->
        <dialog-wrapper
            :dialogObj="classObj"
            @handleClose="classClose"
        >
            <el-menu
                class="el-menu-vertical-demo"
                :default-openeds="openeds"
            >
                <el-submenu
                    v-for="(item, index) in classList"
                    :index="index.toString()"
                    :key="index"
                >
                    <template slot="title">
                        <span>{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item
                            v-for="(i, idx) in item.child"
                            :key="idx"
                            :index="idx.toString()"
                            >{{ i.name }}</el-menu-item
                        >
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </dialog-wrapper>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    MTreeSelect,
    Pagination,
    DialogWrapper,
} from "common-local";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import TableData from "../Sub/TableData.vue";
import {CustodialServicesModel} from "@/models/CustodialServices.js";
import {mapState} from "vuex";
import RecordsList from '@/libs/pageListOptimal.js';
import onResize from "@/mixins/onResize"

export default {
    name: "TrusteeshipStudentView",
    components: {
        FilterData,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
        DialogWrapper,
    },
    mixins: [onResize],
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            // 班级多选
            value: [],
            selectTree: [],
            multipleChoiceClasses: [],
            menuList: [],
            oneDimensionalArrays: [],
            defaultProps: {
                children: "child",
                label: "name",
            },
            openeds: [],
            listQuery: {
                pageRow: 10,
                pageNum: 1,
                title: "",
                gradeId: "",
                classId: "",
                startTime: "",
                endTime: "",
                jobScope: ["2", "3"],
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入项目名称",
                        key: "title",
                    },
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "datetimerange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "请选择开始时间",
                        endPlaceholder: "请选择结束时间",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "班级",
                        key: "classId",
                        list: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["schooldeposit:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["schooldeposit:list"],
                    },
                    {
                        type: "primary",
                        text: "添加",
                        fn: "add",
                        auth: ["schooldeposit:add"],
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        type: "slot",
                        slotName: "title",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "托管日期",
                        prop: "date",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "班级",
                        type: "slot",
                        slotName: "depositClasses",
                        fixed: false,
                        labelWidth: "190",
                        align: 'center',
                        className:'text-spacing-reduction'
                    },
                    {
                        label: "学生数量",
                        prop: "studentNum",
                        fixed: false,
                        align: 'center',
                        labelWidth: '190px'
                    },
                    {
                        label: "创建人",
                        prop: "createName",
                        labelWidth: "190",
                        align: 'center'
                    },
                    {
                        label: "创建时间",
                        prop: "createTime",
                        labelWidth: "190",
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: 'center',
                        className:'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
            // 添加、编辑
            addObj: {
                title: "",
                dialogVisible: false,
                width: "640px",
                type: "add",
            },
            addRules: {
                title: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: ["change", "blur"],
                    },
                ],
                class: [
                    {
                        required: true,
                        message: "请选择班级",
                        trigger: ["blur", "change"],
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "请选择托管日期",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            addRuleForm: {
                id: "",
                title: "",
                depositStartDate: "",
                depositEndDate: "",
                depositClasses: [],
                class: "",
                date: "",
            },
            classObj: {
                title: "班级详情",
                dialogVisible: false,
                width: "640px",
            },
            addClasses: {
                schoolId: "",
                gradeLevel: "",
            },
            classOptions: [],
            classList: [],
            flag: true,
            detailState: true,
            detailAuth: ['deposit:stu']
        };
    },
    created() {
        this.init();
    },
    mounted() {
        this.selectTree = this.flatten(this.multipleChoiceClasses);
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            schoolId: (state) => state.schoolId,
            gradeLevel: (state) => state.gradeLevel,
        }),
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-07 16:55:10
         */
        init() {
            this.initAssignment();
            this.initPageData();
        },
        initAssignment() {
            this.setPageInfo();
            this.addClasses.schoolId = this.schoolId;
            this.addClasses.gradeLevel = this.gradeLevel;


            this.detailState = this.permissions.some(permission => {
                return  this.detailAuth.includes(permission)
            })
        },
        async initPageData() {
            await this.getGradeList();
            await this.getAddClassesList();
            await this.getList();
        },
        async getGradeList() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getYearDropDownList({
                    schoolId: this.$store.state.schoolId,
                    jobScope: ["2", "3"],
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        let gradeList = [],
                            setGrade = this.formData.data.find((item) => {
                                return item.key == "gradeId";
                            });

                        gradeList = res.data.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.id,
                            };
                        });
                        setGrade.list = gradeList;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        async getAddClassesList() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getAddClassesList(this.addClasses)
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;
                        this.setDisabled(data);
                        this.multipleChoiceClasses = JSON.parse(
                            JSON.stringify(data),
                        );
                        this.menuList = JSON.parse(JSON.stringify(data));
                        console.log(this.menuList,'所有年级、班级信息');
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        setDisabled(data) {
            data.forEach((item) => {
                if (item.child && item.child.length > 0) {
                    this.setDisabled(item.child);
                }

                if (item.organType != "4") {
                    this.$set(item, "disabled", true);
                }
                this.oneDimensionalArrays.push(item);
            });
        },
        async getClassList(gradeId) {
            const classIndex = this.formData.data.findIndex(
                (i) => i.key === "classId",
            );
            this.formData.data[classIndex].value = "";
            this.formData.data[classIndex].list = [];
            this.listQuery.classId = "";
            if (gradeId) {
                const custodialServicesModel = new CustodialServicesModel();
                custodialServicesModel
                    .getClassDropDownList({
                        schoolId: this.$store.state.schoolId,
                        gradeId: gradeId,
                        jobScope: ["2", "3"],
                    })
                    .then((res) => {
                        if (res.data.code === "200") {
                            console.log(res, "1111");
                            this.formData.data.forEach((item) => {
                                if (item.key === "classId") {
                                    let classList = res.data.data.map((i) => ({
                                        label: i.name,
                                        value: i.id,
                                    }));
                                    item.list = classList;
                                }
                            });
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
            }
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
          getList(isPaging) {
            this.loadingTable = true;
            this.$forceUpdate();
            if (isPaging) {
                this.listQuery.pageNum = 1;
            }

            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getHostingServicesList(this.listQuery)
                .then((res) => {
                    if (res.data.code == "200") {
                        const list = res.data.data.list;
                        list.forEach((item) => {
                            let time =
                                item.depositStartDate.split(" ")[0] +
                                " - " +
                                item.depositEndDate.split(" ")[0];
                            this.$set(item, "date", time);
                        });
                        setTimeout(() => {
                            this.filterTreeData(list);
                        }, 500);


                        this.table_data = list;
                        this.total = res.data.data.totalCount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.setTableScrollHeight();
                    this.loadingTable = false;
                }).catch(err => {
                    this.loadingTable = false;
                })
        },
        /**
         * @Description: 过滤属性数据
         * @DoWhat: 添加班级详情
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-13 10:03:34
         */
        filterTreeData(list) {
            list.forEach((item) => {
                let classIds,
                    gradeIds,
                    menuList = JSON.parse(JSON.stringify(this.menuList));
                classIds = item.depositClasses.map((subItem) => {
                    return subItem.classId;
                });

                gradeIds = item.depositClasses.map((subItem) => {
                    return subItem.gradeId;
                });
                console.log(menuList,'menuList',this.menuList);

                let ultimateArray = [...new Set(gradeIds), ...classIds];

                this.filterMenu(menuList, ultimateArray);
                this.delMenu(menuList, "show");
                this.$set(item, 'menuList', menuList)
            });
        },
        /**
         * @Description: 树形数据添加标识
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-13 17:32:45
         * @param {*} menuList
         * @param {*} menuCode
         */
        filterMenu(menuList, menuCode) {
            menuList.forEach((item) => {
                menuCode.forEach((subItem) => {
                    if (item.id == subItem) {
                        this.$set(item, "show", true);
                    }

                    if (item.child && item.child.length > 0) {
                        this.filterMenu(item.child, menuCode);
                    }
                });
            });
        },
        /**
         * @Description: 删除多余树形数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-13 17:33:05
         * @param {*} menuList
         * @param {*} pointer
         */
        delMenu(menuList, pointer) {
            for (var i = menuList.length - 1; i >= 0; i--) {
                let item = menuList[i];
                if (!item.hasOwnProperty(pointer)) {
                    menuList.splice(i, 1);
                }

                if (item.child && item.child.length > 0) {
                    this.delMenu(item.child, pointer);
                }
            }
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-12 17:02:58
         */
        flatten(arr) {
            return [].concat(
                ...arr.map((item) => {
                    if (item.children) {
                        let arr = [].concat(
                            item,
                            ...this.flatten(item.children),
                        );
                        delete item.children;
                        return arr;
                    }
                    return [].concat(item);
                }),
            );
        },
        checkChangeClick(data, self, child) {
            let datalist = this.$refs.tree.getCheckedNodes();
            this.value = [];
            this.addRuleForm.depositClasses = [];

            datalist.forEach((item) => {
                this.value.push(item.label);
            });

            let ids = datalist.map((item) => {
                return item.id;
            });

            this.oneDimensionalArrays.forEach((item) => {
                ids.forEach((subItem) => {
                    if (item.id == subItem) {
                        console.log(item, "item--");
                        let obj = {
                            gradeId: item.parentOrg,
                            gradeName: "",
                            classId: item.id,
                            className: item.name,
                        };

                        this.addRuleForm.depositClasses.push(obj);
                    }
                });
            });

            this.addRuleForm.depositClasses.forEach((item) => {
                this.oneDimensionalArrays.forEach((subItem) => {
                    if (item.gradeId == subItem.id) {
                        item.gradeName = subItem.name;
                    }
                });
            });

            this.addRuleForm.class = datalist
                .map((item) => {
                    return item.name;
                })
                .join(",");

            console.log("ids", this.addRuleForm); //需要传递的idlist
        },
        /**
         * @Description: 处理报修时间的显示格式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:54:11
         */
        computedTime(startTime) {
            var dateObj = new Date(startTime);
            var year = dateObj.getFullYear();
            var month =
                dateObj.getMonth() + 1 < 10
                    ? "0" + (dateObj.getMonth() + 1)
                    : dateObj.getMonth() + 1;
            var day =
                dateObj.getDate() < 10
                    ? "0" + dateObj.getDate()
                    : dateObj.getDate();
            var hour =
                dateObj.getHours() < 10
                    ? "0" + dateObj.getHours()
                    : dateObj.getHours();
            var minute =
                dateObj.getMinutes() < 10
                    ? "0" + dateObj.getMinutes()
                    : dateObj.getMinutes();
            var now = new Date();
            var now_new = now.getFullYear(); //年
            if (year == now_new) {
                return month + "-" + day + " " + hour + ":" + minute;
            } else {
                return (
                    year + "-" + month + "-" + day + " " + hour + ":" + minute
                );
            }
        },
        /**
         * @Description: 修改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-12 14:56:39
         */
        changeSel(data) {
            switch (data.key) {
                case "gradeId":
                    this.getClassList(data.value);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 日期选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-12 15:10:16
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
            this.listQuery.startTime = startTime;
            this.listQuery.endTime = endTime;
        },
        /**
         * @Description: 列表-班级
         * @DoWhat: 打开班级详情弹框
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 14:24:44
         */
        handleClass(data) {
            this.openeds = [];
            data.menuList.forEach((item, index) => {
                this.openeds.push(index.toString());
            })

            this.classList = data.menuList;
            console.log(this.classList,'this.classList',data.menuList,data);
            this.classObj.dialogVisible = true;
        },
        classClose() {
            this.openeds = [];
            this.classList = [];
            this.classObj.dialogVisible = false;
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name)
                },500)
            }
        },
        /**
         * @Description: 详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 10:46:43
         */
        handleDetails(data) {
            this.savePageInfo();
            this.$emit("toDetail", {
                data: data,
            });
        },
        /**
         * @Description: 查询/重置/添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    this.listQuery = {
                        pageRow: 10,
                        pageNum: 1,
                        gradeId: "",
                        classId: "",
                        startTime: "",
                        endTime: "",
                        jobScope: ["2", "3"],
                    };
                    this.getList();
                    break;
                case "add": //添加
                    this.addRuleForm = {
                        id: "",
                        title: "",
                        depositStartDate: "",
                        depositEndDate: "",
                        depositClasses: [],
                        class: "",
                        date: "",
                    };
                    this.flag = false;
                    this.addObj.title = "添加";
                    this.addObj.type = "add";
                    this.addObj.dialogVisible = true;
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 操作-编辑
         * @DoWhat: 打开编辑弹窗
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 10:09:37
         */
        handelEdit(data) {
            this.addObj.title = "编辑";
            this.addObj.type = "edit";

            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .hostingServicesCheckStu({id: data.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        this.flag = res.data.data.flag;
                        this.addRuleForm.id = data.id;
                        this.addRuleForm.title = data.title;
                        this.addRuleForm.depositStartDate =
                            data.depositStartDate;
                        this.addRuleForm.depositEndDate = data.depositEndDate;
                        this.addRuleForm.depositClasses = data.depositClasses;

                        this.addRuleForm.class = data.depositClasses
                            .map((item) => {
                                return item.className;
                            })
                            .join(",");

                        this.addRuleForm.date = [
                            data.depositStartDate,
                            data.depositEndDate,
                        ];

                        this.addObj.dialogVisible = true;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 添加、编辑-取消
         * @DoWhat: 取消
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 09:40:27
         */
        addClose() {
            this.$refs.addRuleForm.resetFields();
            this.addObj.dialogVisible = false;
        },
        /**
         * @Description: 获取日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 09:45:51
         */
        getNewDate() {
            if (this.addRuleForm.length > 0) {
                this.addRuleForm.depositStartDate = this.addRuleForm[0];
                this.addRuleForm.depositEndDate = this.addRuleForm[1];
            }
        },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
        /**
         * @Description: 添加、编辑-保存
         * @DoWhat: 保存
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 09:41:38
         */
        addSubmit() {
            this.$refs.addRuleForm.validate((valid) => {
                if (valid) {
                    console.log(this.addRuleForm, "addRuleForm");
                    this.addRuleForm.depositStartDate =
                        this.addRuleForm.date[0];
                    this.addRuleForm.depositEndDate = this.addRuleForm.date[1];

                    const custodialServicesModel = new CustodialServicesModel();
                    custodialServicesModel
                        .hostingServicesSave(this.addRuleForm)
                        .then((res) => {
                            if (res.data.code == "200") {
                                if(this.addObj.type == 'edit'){
                                    this.$message.success("编辑成功");
                                }else{
                                    this.$message.success("添加成功");
                                }
                                this.getList(true);
                                this.addObj.dialogVisible = false;
                                this.addRuleForm = {
                                    id: "",
                                    title: "",
                                    depositStartDate: "",
                                    depositEndDate: "",
                                    depositClasses: [],
                                    class: "",
                                    date: "",
                                };
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                }
            });
        },
        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 09:40:11
         */
        handelDetele(data) {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .hostingServicesCheckStu({id: data.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        let flag = res.data.data.flag;

                        if (flag) {
                            this.cancelDeletion(data);
                        } else {
                            this.continueToDelete(data);
                        }
                    }
                });
        },
        /**
         * @Description: 取消删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-13 16:57:18
         */
        cancelDeletion() {
            this.$confirm(
                "该项目中已包含学生，不可删除，请先删除学生数据。",
                "提示",
                {
                    confirmButtonText: "确定",
                    type: "warning",
                },
            )
                .then(() => {})
                .catch(() => {});
        },
        /**
         * @Description: 继续删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-13 16:57:11
         * @param {*} data
         */
        continueToDelete(data) {
            this.$confirm("删除之后不可恢复，是否确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const custodialServicesModel = new CustodialServicesModel();
                    custodialServicesModel
                        .setHostingServicesDel({id: data.id})
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success("删除成功");
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.content-wrapper{
    padding-right: 10px;
}
.meetings-my-list {
    .content-wrapper .el-date-editor {
        width: 340px !important;
    }

    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}

/deep/ .el-menu-vertical-demo {
    border: solid 1px #e6e6e6 !important;
}
/deep/ .el-submenu__title {
    height: 30px !important;
    line-height: 30px;
    font-size: 14px;
    color: #303133;
    padding: 0;
}
/deep/.el-menu-item-group {
    background: #f7f7f7;
}
/deep/.el-menu-item-group__title {
    padding: 0;
}
/deep/ .el-submenu .el-menu-item {
    height: 20px !important;
    line-height: 20px;
    font-size: 13px;
    color: #303133;
    padding: 0;
}
.open-style {
    min-height: 200px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
}
.stafftree {
    /deep/ .el-tree-node {
        .el-checkbox__input.is-disabled {
            display: none;
        }
    }
}
</style>
