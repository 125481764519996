<!--
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-09-27 09:06:27
 * @LastEditors: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @LastEditTime: 2023-09-27 14:56:39
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\TrusteeshipStudent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 托管服务-托管学生 -->
    <component
        v-bind:is="currentTabComponent"
        :detail-data="detailData"
        :list-data="listData"
        @toList="toList"
        @toDetail="toDetail"
        @setPageInitTabs="setPageInitTabs"
    ></component>
</template>
<script>
import TrusteeshipStudentList from './TrusteeshipStudent/TrusteeshipStudentView.vue';
import TrusteeshipStudentInfo from './TrusteeshipStudent/TrusteeshipStudentInfo.vue';

export default {
    name: "TrusteeshipStudentScrollWrapper",
    components: {
        TrusteeshipStudentList,
        TrusteeshipStudentInfo
    },
    data() {
        return {
            // 切换组件
            compList: ['TrusteeshipStudentList', 'TrusteeshipStudentInfo'],
            idx: 0,
            listData: {},
            detailData: {}
        }
    },
    computed: {
        currentTabComponent() {
            return this.compList[this.idx];
        }
    },
    methods: {
        toList(listData) {
            this.idx = 0
            this.listData = listData
        },
        toDetail(detailData) {
            this.idx = 1
            this.detailData = detailData
        },
        setPageInitTabs() {
            this.$eventDispatch('setGlobalPageType', 'list');
            this.idx = 0;
        }
    }
}
</script>
